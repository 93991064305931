import { useState, useContext  } from 'react'
import logo from '../../assets/imgs/logo.png';
import { Link } from 'react-router-dom'

import './signUp.css'
import '../../assets/style.css'

import { AuthContext } from '../../contexts/auth'

export default function SignUp(){
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { signUp, loadingAuth } = useContext(AuthContext);

  async function handleSubmit(e){
    e.preventDefault();

    if(name !== '' && email !== '' && password !== ''){
     await signUp(email, password, name)
    }

  }

  return(
    <span className='signUp'>
        <div className="container-center">
            <div className="login">
                <div className="login-area logo-adjust">
                <img src={logo} alt="Logo" />
                </div>

                <form onSubmit={handleSubmit}>
                <h1 className='abril-fatface-regular'>Nova conta</h1>
                <input 
                    type="text" 
                    placeholder="Seu nome"
                    value={name}
                    onChange={ (e) => setName(e.target.value) }
                />

                <input 
                    type="text" 
                    placeholder="email@email.com"
                    value={email}
                    onChange={ (e) => setEmail(e.target.value) }
                />

                <input 
                    type="password" 
                    placeholder="********"
                    value={password}
                    onChange={ (e) => setPassword(e.target.value) }
                />

                <button type="submit">
                    {loadingAuth ? 'Carregando...' : 'Cadastrar'}
                </button>
                </form>

                <Link to="/">Já possui uma conta? Faça login</Link>

            </div>
        </div>
        <span className="ajusteCopyright">&copy; Copyright 2000-2023 Todos os direitos reservados</span>
    </span>
  )
}